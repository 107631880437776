.dropdown-styling{
  background-color: #fff !important;
}

.icon-padding{
  margin-left: 10px !important;
}
.modal-background{
  background-color: #efefef !important;
  margin-top: 0px !important;
}
.dark-back{
  background-color: #ddd;
}
.btn-success-bg{
  border:none !important;
  padding-left: 60px;
  padding-right: 60px;
  border-radius: 0px !important;
}
.btn-job{
  border-radius: 2px !important;
  border: none !important;
  font-size: 13px !important;
  padding: 6px 15px 6px 15px !important;
}
.btn-customer{
    padding: 0px 15px 0px 15px !important;
    border-radius: 2px !important;
    border: none !important;
    font-size: 13px !important;
}
.button-padding{
  margin-top:28px;
}
.delete-icon-job{
  margin-left: 10px;
}
 .job-title{
  color:#000000 !important;
 }
 .white-background{
  background-color: #fff;
 }
 .add-job-back{
  background-color: #CEE4C8;
 }
 .form-padding{
  padding: 0px 20px 0px 20px;
  }
.btn-job-primary{
  background-color: #09819A !important;
  outline:none !important;
  box-shadow: none !important;
  font-size:16px !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 14px !important;
  /* padding: 3px 15px 5px 15px !important; */
  padding: 6px 10px;
  line-height: 1.5em;
}
.btn-job-primary:hover{
  background-color: #001E2E !important;
  outline:none !important;
  box-shadow: none !important;
}
.btn-job-primary:focus{
 background-color: #001E2E !important;
  outline:none !important;
  box-shadow: none !important;
}
.input-tags{
  padding: 1.5rem 0.75rem !important;
}
.btn-search{
  border-radius: 3px !important;
  margin-top: 42px;
  border: none !important;
  font-size: 14px !important;
  /* padding: 3px 15px 5px 15px !important; */
  padding: 6px 10px;
  line-height: 1.5em;
}
.btn-other{
  border-radius: 3px !important;
  border: none !important;
  font-size: 14px !important;
  /* padding: 3px 15px 5px 15px !important; */
  padding: 6px 10px;
  line-height: 1.5em;
}
.job-card{
  padding-right: 0px;
  padding-left: 0px;
  max-height: 280px;
  background-color: #fff;
  margin-top: 10px;
  height: 280px;
  box-shadow: 0px 3px 6px #00000029;

}

.job-dropdown{
  background-color: #fff;
  color:#000;
}
.center-align-job{
  text-align:center !important;
}
.btn-geofence{
  border-radius: 0px !important;
  border: none !important;
  font-size: 18px !important;
  padding: 5px 15px 5px 15px !important;
}
.geofence-padding{
  padding-right: 0px !important;
}
.phoneFormPadding{
  padding-left: 0px !important;
}
.listNone{
  list-style: none !important;
}
.table-top{
  margin-top: 0px !important;
}
.customer-details-title{
  font-weight: bold;
  color:#000000 !important;
}
.customer-details-subtitle{
  color: #636c72 !important;
}
.border-btm{
  border-bottom-color: lightgray;
}

.margin-modal-tag{
  margin-top:-30px;
}
.customer-view-table{
  font-size:14px;
}
.customer-view-head{
  font-size:15px;
  font-weight: 500;
}

.status-modal{
  font-size: 17px;
  font-weight: 500;
  vertical-align: middle;
}
.btngroup .btn{
  padding: 4px;
  font-size: 13px;
}
.job-task-table {
  height: 257px;
  overflow-y: auto;
}
.job-task-margin{
  margin-left: 5px;
}
.job-task-margin-middle{
  margin-left: 35px;
}
.button-right-margin{
  margin-right:5px;
}
.customer-button-margin{
  margin-left: 145px;
}
.modal-footer-submit{
justify-content: flex-end;
}
.summary-other-padding{
  padding-left: 40px;
}
.summary-other-padding-last{
  padding-left: 75px;
}
.elipsis-job{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-title{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 180%;
}
.phone-margin{
  margin-left: 22px;
}
.phone-override{
  max-width: 76% !important
}

.override-cross .input-icon__clear{
  right:63px !important;
}
.override-notes .invalid-feedback{
  min-height: 0px;
}
.override-notes .form-text{
  display: none;
}
.job-notes-scroll{
  display: block;
  max-height: 620px;
  height: 620px;
  overflow-y: auto;
  margin-top: -5px;
  background-color: #efefef;
  border: 1px solid lightgrey;
  border-top: none;
  border-bottom: none;
}
.job-notes-scroll-modal{
  display: block;
  max-height: 320px;
  height: 320px;
  overflow-y: auto;
  background-color: #efefef;
  border: 1px solid lightgrey;
  border-top: none;
  border-bottom: none;
}
.job-card-left-padding{
  padding-left: 5px;
}

.job-card-right-padding{
  padding-right: 5px;
}
.override .dropdown-arrow{
  top: 32px !important;
}
.task-arrow .dropdown-arrow{
  top: 42px !important;
}

.tags-back{
    font-size: 12px;
    border-radius: .2rem;
    color: #000 !important;
    background-color: #ddd;
}
.report-form {
  color: #242424 !important;
}
.report-form .form-inline {
  display:block !important;
}
.report-form .form-inline label{
  display:inline !important;
}
.report-form .form-inline .form-control-label{
  vertical-align: text-bottom;
}
.override .invalid-feedback{
  display: block !important;
}


.progress-bar-margin{
  margin-left:180px;
}
.steps-form {
  display: table;
  width: 100%;
  position: relative;
}
.steps-form .steps-row {
  display: table-row;
}
.steps-form .steps-step:after {
  top: 39px;
  left: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 5px;
  background-color: #ddd;
}
.steps-form .steps-row .steps-step,
.steps-form .steps-row .steps-step-geo {
  display: table-cell;
  text-align: center;
  position: relative;
  width: 20%;
}

.steps-form .steps-step-geo:after {
  top: 39px;
  left: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 5px;
  border: 2px dotted #ddd;
}


.steps-form .steps-row .steps-step p,
.steps-form .steps-row .steps-step-geo p {
  margin-top: 0.5rem;
}
.steps-form .steps-row .steps-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.steps-form .steps-row .steps-step .btn-circle,
.steps-form .steps-row .steps-step-geo .btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 20px;
  line-height: 0.8;
  border-radius: 15px;
  margin-top: 0;
  z-index:1;
  position: inherit;
  cursor:default !important;
}
.btn-circle{
  background-color: #ddd;
  color: white;
  width: 49px;
  height: 49px;
  text-align: center;
  padding: 5px 0;
  font-size: 20px;
  line-height: 2.00;
  border-radius: 30px;
}

.active-btn{
  background-color:#00b147;
  color: white !important;
}

.active-btn hover{
  background-color:green;
  color: white !important;
}
.steps-form .active-div:after {
  background-color: #00b147;
}

  .blue-background{
    background-color: #F0F9FE;
  }
  .date-padding-left{
    padding-left: 35px;
  }
  .date-padding-right{
    padding-right: 35px;
  }
  .notes-display-comment{
    padding-top: 160px;
    padding-left: 130px;
    color: #8e8e8e;
    padding-right: 130px;
    text-align: center;
  }
  .image-notes{
    width: 115px;
    height: 115px;
  }
  .notes-user{
    color:#162d6e !important;
    font-size: 14px;
  }
  .notes-date{
    color:#162d6e !important;
    font-size: 11px;
  }
  .quick-btn-override .btn-defaultColor{
    color:white ;
    background-color: darkgray;
    width:80px;
  }
  .quick-btn-override .btn-primary{
    width:80px;
  }

.blue-background{
  background-color: #F0F9FE;
}
.date-padding-left{
  padding-left: 35px;
}
.date-padding-right{
  padding-right: 35px;
}
.notes-display-comment-modal{
  padding-top: 70px;
  padding-left: 130px;
  color: #8e8e8e;
  padding-right: 130px;
  text-align: center;
}
.image-notes{
  width: 115px;
  height: 115px;
}
.notes-user{
  color:#162d6e !important;
  font-size: 12px;
}
.notes-date{
  color:#162d6e !important;
  font-size: 11px;
}
.blue-background{
  background-color: #F0F9FE;
}
.date-padding-left{
  padding-left: 35px;
}
.date-padding-right{
  padding-right: 35px;
}
.file-browser{
  padding: 10px;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
}

.override-icon-job .file-browser{
  padding-top: 7px !important;
  text-align: center;
}
.image-model-preview .modal-dialog{
  max-width: 630px !important;
}
.table-emp .table tbody tr td {
    max-width: 175px;
    min-width: 175px;
}
.table-timecard-main .table tbody tr td {
    max-width: 140px;
    min-width: 141px;
}
.dropnowidth{
  @extend .dropdown-override;
  width:10px;
}
.dropdown-override .btn-accent{
  background: #fff;
  margin-right: 8px;
  color: #242424;
  border: 1px solid #B1BABF;
  font-size: 14px;
  text-align: left;
  .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.3em;
    vertical-align: middle;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
    margin-top: 7px !important;
    float: right !important;
  }
}
.custom-dropdownoverride .dropdown-override{
  width: 100px !important;
}
/* To set the height of Active toggle on customer list page */
.dropdown-override {
  button.dropdown-toggle {
    height: 33px !important;
  }
}
.btn-dropdown-white{
    color: #242424;
    background-color: #fff;
    font-weight:300;
    &:disabled{
      color: white !important;
      opacity: 1;
      background-color: #adabab !important;
    }
}
.btn-dropdown-white :hover{
    background-color: #fff;
}
.multiatom-wrapper .btn-accent{
    background: #fff;
    margin-right: 0px;
    color: black;
    font-weight: bold;
}

.multiatom-wrapper .btn-accent-disabled{
  background: #adabab  !important;
  opacity: 1;
}

.multiatom-wrapper button{
    font-weight: 400;
}
.multiatom-wrapper{
  border: 1px solid #B1BABF;
}
.job-table{
  border:2px solid #eceeef;
}
.job-color-head{
  background-color: #EAF4E9;
}
.task-override .dropdown-menu {
  width: 100%;
}
.task-override .btn-accent{
    max-width: 320px;
    padding: 0px;
    font-size: 12px;
    padding-left: 7px;
    padding-right: 7px;
    height: 27px;
    &:disabled{
     height: 34px !important;
    }
  }

.emp-job-override .btn-accent{
    max-width: 320px;
    padding: 0px;
    font-size: 12px;
    padding-left: 7px;
    padding-right: 7px;
    height: 27px;
  }

.btn-task{
    border-radius: 3px !important;
    border: none !important;
    font-size: 12px !important;
    padding: 7px;
    line-height: 1;
    margin-top: 40px;
}
.emp-job-override .btn-group {
    background-color: #fff;
    border-color: #d9d9d9 #ccc #b3b3b3;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #333;
    cursor: default;
    /* display: table; */
    border-spacing: 0;
    /* border-collapse: separate; */
    height: 36px;
    outline: none;
    /* overflow: hidden; */
    position: relative;
}

.task-override .btn-group {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  /* display: table; */
  border-spacing: 0;
  /* border-collapse: separate; */
  height: 36px;
  outline: none;
  /* overflow: hidden; */
  position: relative;
}

.task-override {
  span.Select-clear-zone {
    visibility: hidden;
  }
}

.task-tag-dropdown {
  button.tag-dropdown {
    font-size: 14px !important;
  }
}

.custom_accent_button {
  margin-left: 3rem;
  background-color: #FFF;
  color: #388c38;
  padding: 0.2rem !important;
  border: 1px solid #388c38 !important;
  text-align: center;
}
.custom_accent_button:hover {
  color: #388c38 !important;
  background-color: #FFF !important;
  border: 1px solid #388c38 !important;
}
.emp-job-btn{
  line-height: 1em !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  font-size: 12px !important;
}
.emp-dropdown-override .Select-input{
  height: 33px !important;
  width: 140px;
}
.select-input-width .Select-input{
  width : 100%;
}
.emp-dropdown-override .Select-control{
  height: 25px !important;

  span.Select-clear-zone {
    visibility: hidden;
  }
}
.task-tag-dropdown {
  button.tag-dropdown {
    height: 34px !important;
    width: 100%;
    padding-left: 9px !important;
  }
}
.menu-demo-style{
    font-size: 12px !important;
    padding: 3px 9px !important;
}
.emp-dropdown-override .Select-placeholder{
  line-height: 33px !important;
}
.emp-dropdown-override .Select-value{
  line-height: 29px !important;
}
.emp-dropdown-override .Select-value-label{
  font-size: 12px;
}
.emp-dropdown-override .select-disabled{
  width: 85.7% !important;
}
/* Geofence dropdown button styles */
.geofence-arrow {
  input {
    height: 34px !important;
    padding-left: 2px;
  }
}
.tag-wrapper{
  word-break: break-all;
}
.team-wrapper{
  padding-left: 4.5rem !important;
}
.scroll-edit-job{
  max-height: 260px;
  overflow-y: auto;
  transform: translate(0,0) !important;
  margin-top: 35px;
}
.delete-icon-job {
  position: absolute;
  cursor: pointer;
  right: 3px;
  top: -13px;
  display: none;
  outline: none;
  z-index: 99;
}
.show-del {
  position: relative;
}

.show-del:hover .delete-icon{
  display: inline-block !important;
}

.show-del:hover .delete-icon-job{
  display: inline-block !important;
}
.job-notes-image{
  max-width: 250px !important;
  max-height: 120px !important;
}

.job-notes-quick-view{
  max-width: 200px;
  height: 50px;
  width: auto;
}

.arrow-right :after{
  content: "\f078";
}
.sort-right{
  transform: rotate(90deg);
}
.sub-table-timecard{
  min-width:90px !important;
}
.editable-wrapper .form-control{
  padding: 0.05rem 0.15rem !important;
  /* width:50% !important; */
  /*width: 66px;*/
  border: none;
  text-align: center;
}

.editable-text-right .form-control{
  text-align: right !important;
}

.editable-text-left .form-control{
  text-align: left !important;
}

.editable-wrapper .form-control:disabled{
  background-color: #efefef !important;
}

.editable-wrapper{
  text-align: center;
  border-right: 1px solid #ddd !important;
  border-left: 1px solid #ddd !important;
}

.editable-job-wrapper .form-control{
  border: none;
  text-align: right !important;
}

.editable-cell-wrapper{
  padding-right: 20px !important;
}

.tooltip-override .dropdown-item{
  font-size: 12px;
}
.modal-adjustment{
  max-height: 336px !important;
  overflow-y: scroll;
}

.adjustment-head{
  max-height: 42px;
  font-size: 15px;
  height: 42px;
  background-color: #D5D9DB;
  border:1px solid #C0C0C0;
}

.adjustment-input .input-group .form-control{
  padding: 0.25rem 0.22rem !important;
}

.adjustment-input .invalid-feedback{
  display:none;
}
.adjustment-border{
  border-right: 1px solid #C0C0C0;
}
.adjustment-background{
  background-color: #efefef;

}
.adjustment-table{
  border-left: 1px solid #C0C0C0;
  border-bottom: 1px solid #C0C0C0;
}
.adjustment-table .invalid-feedback{
  display: none !important;
}
.adjustment-table .form-text{
  display: none !important;
}

.table-adjustment {
  table {
    background-color: #efefef;
  }
  tbody {
    display:block;
    max-height:450px;
    overflow-y:scroll;
  }
  thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
  }
  thead {
    width: calc( 100% - 0.4em )
  }
}

.job-select-day{
    color: white;
    border: 1px solid #09819A;
    background-color: #09819A;
    text-align: center;
    width: 14%;
    margin-right: 2%;
    font-size: 10px;
    font-weight:400;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    &:focus{
      outline: none;
    };
}



.job-select-day-other{
    margin-right: 2%;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DDD;
    border-radius: 2px;
    font-size: 10px;
    font-weight:400;
    color: darkgray;
    width: 14%;
    &:focus{
      outline: none;
    };
}

.round-button{
  height: 74px;
  width: 74px;
  display: inline-block;
  border-radius: 50%;
  background: #D8D8D8;
}

.form-padding .input-group .form-control{
  flex-direction: initial !important;
}
.qrcode-css{
  height:30px;
  width:40px;
  zoom:4;
}
.record-font{
  font-size:10px !important;
  margin-left: 3px;
}
.subhead-font{
  font-size:11px !important;
}

.text-job-modal{
  margin-bottom: 0px;
  font-size:11px !important;
  font-weight: 500 !important;
}

.margin-job-modal{
  margin-top: -5px;
}

.popover.popover-top::after, .popover.bs-tether-element-attached-bottom::after {
  border-top-color: #949494;
}

.popover{
  background-color: #949494;
  border: none;
}

.popover.popover-bottom::after, .popover.bs-tether-element-attached-top::after {
  border-bottom-color: #949494;
}

.ReactTable {
  & .customer_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left !important;
  }
  & .rt-tr {
    &:hover {
      background: #9CDAD7 !important;
    }
  }
  & .rt-td {
    height: 33px;
  }
}

.comment-box-dt{
  font-size: 12px;
}

.managingDTdate{
  font-size: 9px;
}
.close-icon{
  margin-top: -5px !important;
}

.managingDTInfodate{
  font-size:12px;
}

.editable-wrapper .custom-work-day-dt{
  width: 45px !important;
  font-size: 9px !important;
}

.popover-override .dropdown-item{
  font-size: 12px;
}

.popover-override-jobs{
  top: 37px !important;
}

.meal-editable{
  border-bottom: 1px solid #ddd !important;
}

.infinite-scroll-ovverride .infinite-scroll-component{
  overflow-x:hidden !important;
}

.popover.popover-top::after, .popover.bs-tether-element-attached-bottom::after {
  border-top-color: #949494;
}

.popover{
  background-color:white;
  border: none;
}

.popover.popover-bottom::after, .popover.bs-tether-element-attached-top::after {
  border-bottom-color: white;
}

.ReactTable {
  & .customer_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left !important;
  }
  & .rt-tr {
    &:hover {
      background: #9CDAD7 !important;
    }
  }
}

.ReactTable {
  & .rt-tbody {
    & .rt-tr-group {
      border-bottom: none !important;
    }
  }
  & .rt-thead {
    height: 39px;
    box-shadow: none !important;
    & .rt-tr {
      padding-top: 0.3rem;
      background: #001E2E !important;
      color: #fff;
      font-size: 12px !important;
      font-weight: 700;
      padding-top: 0.2rem;
      & .rt-th {
        border: none !important;
        padding-top: 0.6rem !important;
        border-right: 1px solid #F2F2F2 !important;
      }
    }
  }

  & .rt-tbody .rt-td {
    color: #242424;

    b {
      color: #0F2BB4 !important;
      font-weight: 500;
    }
  }

  .rt-head .rt-tr .rt-th {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
}

.card-header-right-search{
    margin-right: 1rem;
    & .input-group {
      /* width: 205px; */
    }
    form {
      margin-top: 0;
    }
}

/* Custom accent button styles */
.custom_accent_assign_button {
  background-color: #FFF !important;
  color: #388c38 !important;
  height: 33px;
  border: 1px solid #388c38 !important;
  text-align: center;
}

button.custom_accent_assign_button:hover {
  color: #388c38 !important;
  background-color: #FFF !important;
  border: 1px solid #388c38 !important;
}

/* Assign button styles */
.assign-button {
  text-align: center;
  background-color: #09819A !important;
  color: #fff !important;
  height: 33px;
  width: 111px;
  font-size: 16px !important;
  border: none !important;
  cursor: pointer;
    &:hover {
      background: #001E2E !important;
  }
}

.remove-icon .Select-clear-zone{
  visibility: hidden;
}

.remove-icon {
  div.Select-value {
    .Select-clear-zone {
      visibility: hidden;
    }
  }
}

.select-width .Select-input {
  width: 100% !important;
}

/* Styles for Job Employee filter */
.job-employee-filter {
  button.tag-dropdown {
    color: #aaa !important;
    font-size: 14px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Styles for Job Task Filter */
.job-task-filter {
  button.tag-dropdown {
    color: #aaa !important;
    font-size: 14px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.button_group_container .btn-defaultColor{
  background-color: white;
}



.button_group_container{
  height: 34px;
  Button{
    border: 1px solid #B8B8B8;
  }
}

.modal_header_color .modal-title {
  color: #242424 !important;
}

.light-text {
  color: #aaa !important;
}

/* CSS to target Job Status dropdown menu in Job Create (Job Center Dashboard) */
#jobSchedule div.dropdown div.dropdown-override {
  button {
    height: 36px !important;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button::after{
    margin-top:0px;
  }
}

.job-type-text {
  color: #242424;
  font-size: 14px;
  font-weight: 500;
}

.black-text {
  color: #242424 !important;
}

.parent-heading{
  font-size: 30px;
}

.parent-desc{
  font-weight:300;
  font-size: 12px;
}

/* Active select button style on geo-fence page */
#geo-fence .dropdown .dropdown-override {
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button::after{
    margin-top:0px;
  }
}

/* Drop down button styles on Add Employee and Add task page */
.emp-job-override .dropdown-menu {
  width: 100%;
  max-height:200px;
  overflow-y:auto;
  .dropdown-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.emp-task-override .dropdown-menu {
  max-height:200px;
  overflow-y:auto;
}

.task-override .dropdown-menu {
  width: 100%;
  .dropdown-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.emp-job-override, .task-override, .task-tag-dropdown {
  .dropdown-toggle::after {
    content: "";
    margin-right: 5px;
    margin-top: 8px;
    height: 0;
    width: 0;
    transform: none !important;
    padding: none !important;
    border-left: 4.7px solid transparent;
    border-right: 4.7px solid transparent;
    border-top: 5px solid #000000;
  }
  .btn-accent-disabled::after{
    margin-top: 2px !important;
    border-top: 5px solid #fff !important;
  }
}

.custom-task-override {
 .dropdown-toggle::after {
    content: "";
    margin-right: 5px;
    margin-top: 0px !important;
    height: 0;
    width: 0;
    transform: none !important;
    padding: none !important;
    border-left: 4.7px solid transparent;
    border-right: 4.7px solid transparent;
    border-top: 5px solid #999;
  }
}

.dropdown-override .dropdown-toggle:after {
  float: right;
  margin-top: 8px;
}

/* Drop down button styles on Job Detail and Geofence Table */
.override-dropdown, .geofence-arrow {
  .dropdown-arrow {
    top: 39px;
    border: none;
    border-width: none;
    transform: none !important;
    padding: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5.4px solid #000000;
  }
}

.override-dropdown {
  input {
    padding-left: 0.25rem;
  }
}

/* Job Reports Page Styles */
.job-report-filter-container {
  background-color: #FFF;
  color: #242424;
  padding: 1rem;
  display:flow-root;

  label.col-form-label {
    display: flex;
    justify-content: flex-start;
  }
}

/* Job Reports table styles */
.table-reports {
  thead tr {
    color: #162D6E
  }
}

// Job Reports dropdown styles overwrite
div.job-report-multiselect, div.task-arrow {
  div.custom-multi-select {
    span.dropdown-arrow {
      border-color: #001E2E transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
      display: inline-block;
      height: 0;
      width: 0;
      position: relative;
      transform: rotateZ(0deg);
      padding: 0;
      cursor: pointer;
      // top: 48% !important;
    }
  }
}

.grouped-by-btn {
  button.dropdown-toggle {
    background-color: #FFF !important;
    color: #242424 !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    padding: 0.2rem 0.2rem;
    min-width: 120px;
    border-radius: 5px;
    cursor: pointer;
  }

  // Dropdown button styles
  button.dropdown-toggle::after {
    border-color: #001E2E transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    position: relative;
    transform: rotateZ(0deg);
    padding: 0;
    cursor: pointer;
    margin-left: 0.5rem;
  }

  div.dropdown-menu-right {
    width: 100%;
  }
}

// CSS to alter the text color in geofence modal
h4.modal-title {
  color: #242424;
}

// CSS to target pagination component text inside the Geofence List Page
section.geo-fence-list, section.geo-fence-map, section.task-list, section.customer-list {
  button {
    color: #242424 !important;
  }

  p {
    color: #242424 !important;
  }
}

// Geofence map section styles
section.geo-fence-map {
  height: 60vh;
  margin-top: 1rem;
  padding-bottom: 5vw;
}

// Geofence modal and task modal active checkbox style Change
input[type="checkbox"]#is_billable, input[type="checkbox"]#is_active {
  vertical-align: text-bottom;
}

// Job Detail Page styles
.job-detail {
  background-color: #FFF;
  padding: 0px 80px;
  color: #242424;

  span.Select-value-label {
    color: #242424 !important;
  }

  div.input-group {
    input {
      color: #242424;
      height: 38px !important;
    }
  }
}

.corner-round .input-group .form-control {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

@media screen and (min-width: 2560px) {
  .job-select-day-other{
    margin-right: 1%;
  }
}
.drop-item .dropdown-item{
  font-family: 'MuseoSans', Roboto !important;
  font-weight: 300;
  color:#242424 !important;
  text-overflow:ellipsis;
  overflow:hidden
}
.drop-item :hover{
  background-color: #9CDAD7!important;
}
.customer-card{
  box-shadow: 0px 3px 6px #00000029;
}
.table-adjustment-header{
  background-color:#001E2E;
  color:#fff;
}

.override-dropdown-tags {
  .dropdown-arrow {
    top: 16px;
    border: none;
    border-width: none;
    transform: none !important;
    padding: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5.4px solid #000000;
  }
}

.tooltip.show{
  opacity: 1 !important;
}

.hover-employee-tooltip .tooltip-inner{
  background: #E2ECF1;
  position:relative;
  max-height: 150px;
  overflow-y:auto;
  width: 94%;
}

.hover-employee-tooltip .tooltip-inner::-webkit-scrollbar {
  display: none;
}

.hover-employee-tooltip .arrow{
  right: -5.5%;
  margin-top: -2px;
  border-left-color: #E2ECF1 !important;
  border-left-width: 0;
  content: "";
  border-width: 11px;
  display: block;
  width: 0;
  position: absolute;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.override_alignment  input{
  text-align:left !important;
}


.employee-list-filter-container {
  display:flow-root;

  label.col-form-label {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 1px !important;
  }
}
